@import "/Users/timotheus/Local Sites/maxfive/app/public/wp-content/themes/Default/Assets/Scss/MediaQueries.scss";
@import "/Users/timotheus/Local Sites/maxfive/app/public/wp-content/themes/Default/Assets/Scss/_Mixins.scss";
@import "/Users/timotheus/Local Sites/maxfive/app/public/wp-content/themes/Default/Assets/Scss/_Variables.scss";


// BASE -- Global  here

html {
    // transform: scale(2);
}

.applynow {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translate(0,-50%);
    width: 9em;
    z-index: 1;
    height: auto;
    transition: all 0.33s ease;
    opacity: 1;
    z-index: 99999;
    .page-template-home & {
        top: 80%;
    }
    @media all and (max-width: 786px) {
        top: 80%;
    }
}

.blogEntry {
    .section-threeElements,
    .section-fourElements {
        margin-bottom: 0;
        padding-bottom: 50px;
        background-color: white;
    }
    .tagCloudContainer + .section-threeElements,
    .tagCloudContainer + .section-fourElements {
        padding-top: 0;
        @media all and (min-width: 768px) {
            padding-top: 50px;
        }
        @media all and (min-width: 1024px) {
            padding-top: 190px;
        }
    }
}


$textColorBlack: #000;
$textColorWhite: #fff;
$GTWalsheimRegular: 'GTWalsheimRegular';
@mixin doubleTeaserSliderBgText {
	font-family: $GTWalsheimRegular;
	@include rem(font-size, 3.2);
	line-height: 1.3em;
}
@mixin doubleTeaserDetail {
	font-family: $GTWalsheimRegular;
	@include rem(font-size, 1.6);
	line-height: 1.25em;
}
@mixin doubleTeaserEmaillink {
	@include doubleTeaserDetail;
}
.swiper-new {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    .flickity-viewport {
        height: 100% !important;
    }
    .slide {
        height: 100%;
        width: 100%;
        .detail {
            @include doubleTeaserDetail;
            border-bottom: 1px solid $textColorWhite;
        }
        .detail,
        .headline,
        .subheadline {
            color: $textColorWhite;
        }
        &.textColorBlack {
            .detail,
            .headline,
            .subheadline {
                color: $textColorBlack;
            }
            .detail {
                border-bottom: 1px solid $textColorBlack;
            }
        }
        .subheadline {
            margin-bottom: 21px;
        }
        .detail {

        }
        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            padding-bottom: 6%;
            z-index: 2;
            @include mq("phoneBig-"){
                width: 70%;
            }
            &.bgText {
                top: 60px;
                left: 60px;
                transform: translate(0%,0%);
                text-align: left;
                width: 68%;
                .headline {
                    @include doubleTeaserSliderBgText;
                }
            }
        }
    }
}