
.section-accordion {
    // padding: 2.5625em 0;
    padding: 2.5625em 10.5%;
    width: 100%;
    background: #fff;

    .single-post & {
        padding: 0 10.5% 2.5625em 10.5%;
    }
    .item-wrapper {
        @media only screen and (min-width: 768px) {
            width: 79.5%;
            margin: 0 auto;
        }
    }
    .item {
        cursor: pointer;
    }
    .title-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .indicator {
        width: 14px;
        height: 15px;
        background: #494948;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 0.25em;
        @media only screen and (min-width: 768px) {
            margin-top: 0.45em;
            
        }
    }
    .title {
        font-family: 'GTWalsheimRegular';
        font-style: normal;
        font-weight: 700;
        // font-size: 16px;
        // line-height: 23px;
        font-size: 2rem;
        line-height: 1.5em;

        margin-left: 10px;
    }
    .text-wrapper {
        max-height: 0;
        overflow: hidden;
        margin-bottom: 43px;
    }
    .text {
        font-family: 'GTWalsheimLight';
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        // line-height: 23px;
        font-size: 2rem;
        line-height: 1.5em;

        color: #9E9E9E;
        margin-top: 13px;
    }
}